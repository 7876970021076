import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import SettingsIcon from '@material-ui/icons/Settings';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { withContext } from './App';
import { withI18n } from 'react-i18next';
import i18n from 'i18next';
import AsyncSelect from 'react-select/lib/Async';
import { localModel } from './localModel';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import history from './history';

const styles = theme => ({
	root: {
		flexGrow: 1,
		display: "flex",
	},
	alignSelect: {
		flex: "1 1 auto",
	},
	alignButton: {
		top: "9px",
	},
	input: {
		display: 'flex',
		padding: 0,
		//height: "32px",   When I fix the height, it does not work if I add multiple lines of items.
	},
	valueContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		alignItems: 'center',
		overflow: 'hidden',
	},
	chip: {
		margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
	},
	chipFocused: {
		backgroundColor: emphasize (
			theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
			0.08,
		),
	},
	noOptionsMessage: {
		padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
	},
	singleValue: {
		fontSize: 16,
	},
	singleValueDisabled: {
		fontSize: 16,
		color: theme.overrides.MuiInput.root["&$disabled"].color,
	},
	placeholder: {
		position: 'absolute',
		left: 2,
		fontSize: 16,
	},
	paper: {
		position: 'absolute',
		zIndex: 1,
		marginTop: theme.spacing.unit,
		left: 0,
		right: 0,
	},
});

function NoOptionsMessage(props) {
	return (
		<Typography
				color="textSecondary"
				className={props.selectProps.classes.noOptionsMessage}
				{...props.innerProps}>
			{props.children}
		</Typography>
	);
}

function inputComponent({ inputRef, ...props }) {
	return <div ref={inputRef} {...props} />;
}

function Control(props) {
	return (
		<TextField
				fullWidth
				InputProps={{
					inputComponent,
					inputProps: {
						className: props.selectProps.classes.input,
						inputRef: props.innerRef,
						children: props.children,
						...props.innerProps,
					},
				}}
			{...props.selectProps.textFieldProps}
		/>
	);
}

function Option(props) {
	return (
		<MenuItem
				buttonRef={props.innerRef}
				selected={props.isFocused}
				component="div"
				style={{
					fontWeight: props.isSelected ? 500 : 400,
				}}
				{...props.innerProps}>
			{props.children}
		</MenuItem>
	);
}

function Placeholder(props) {
	return (
		<Typography
				color="textSecondary"
				className={props.selectProps.classes.placeholder}
				{...props.innerProps}>
			{props.children}
		</Typography>
	);
}

function SingleValue(props) {
	return (
		<Typography className={props.isDisabled ? props.selectProps.classes.singleValueDisabled : props.selectProps.classes.singleValue} {...props.innerProps}>
			{props.children}
		</Typography>
	);
}

function ValueContainer(props) {
	return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

/*
function IndicatorsContainer(props) {
	const { children, className, cx, getStyles, selectProps } = props;
	
	return (
		<div className={cx(css(getStyles('indicatorsContainer', props)), { 'indicators': true }, className)}>
			{children}
			{
				selectProps.onOpenClick != null &&
					<Tooltip title={selectProps.t('open')} disableFocusListener>
						<OpenInBrowserIcon style={{cursor: "pointer"}} onMouseDown={selectProps.onOpenClick}/>
					</Tooltip>
			}
			{
				selectProps.onManageClick != null &&
					<Tooltip title={selectProps.t('manage')} disableFocusListener>
						<SettingsIcon style={{padding: 0, marginLeft: 10, cursor: "pointer"}} onMouseDown={selectProps.onManageClick}/>
					</Tooltip>
			}
			{
				selectProps.enableable &&
					<Checkbox
							style={{padding: 0, paddingLeft: 10}}
							disabled={false}
					/>
			}
		</div>
	);
}
*/

function MultiValue(props) {
	return (
		<Chip
				tabIndex={-1}
				label={props.children}
				className={classNames(props.selectProps.classes.chip, {
					[props.selectProps.classes.chipFocused]: props.isFocused,
				})}
				onDelete={props.removeProps.onClick}
				deleteIcon={<CancelIcon {...props.removeProps} />}
		/>
	);
}

function Menu(props) {
	return (
		<Paper square elevation={1} className={props.selectProps.classes.paper} {...props.innerProps}>
			{props.children}
		</Paper>
	);
}

class AutoComplete extends React.Component {
	constructor(props) {
		super(props);
		this.handleLoadOptions = this.handleLoadOptions.bind(this);
		this.refreshData = this.refreshData.bind(this);
		this.handleManageClick = this.handleManageClick.bind(this);
		this.handleOpenClick = this.handleOpenClick.bind(this);
	}
	
	handleManageClick(event) {
		event.stopPropagation();
		history.push("/admin/" + this.props.entityName);
	}
	
	handleOpenClick(event) {
		event.stopPropagation();
		if (this.props.asyncRef.current != null
				&& this.props.asyncRef.current.select.state.value != null
				&& this.props.asyncRef.current.select.state.value.value != null) {
			history.push("/admin/" + this.props.entityName + "/" + this.props.asyncRef.current.select.state.value.value + "/view");
		}
	}
	
	getLabelAttributesQueryString(entityModel, entityLocalModel, exceptionAttribute, depth) {
		let str = "";

		if (depth == null) {
			depth = 1;
		}
		
		Object.values(entityModel.attributes)
			.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].label && (entityLocalModel.attributes[attribute.name].labelLanguage == null || entityLocalModel.attributes[attribute.name].labelLanguage == i18n.language))
			.forEach(attribute => {
				if (exceptionAttribute == null || attribute.name != exceptionAttribute.name) {
					if (entityLocalModel.attributes[attribute.name].type == "DOCUMENT") {
						str += attribute.name + " { name thumbnail type }, ";
					}
					else {
						str += attribute.name + ", ";
					}
				}
			});
		
		if (depth < 5) {
			Object.values(entityModel.references)
				.filter(reference => reference.referenceAttributeName != null && entityLocalModel.attributes[reference.referenceAttributeName] != null && entityLocalModel.attributes[reference.referenceAttributeName].label && (entityLocalModel.attributes[reference.referenceAttributeName].labelLanguage == null || entityLocalModel.attributes[reference.referenceAttributeName].labelLanguage == i18n.language))
				.forEach(reference => {
					const model = this.props.context.model;

					let subLabel = this.getLabelAttributesQueryString(model.entities[reference.referencedKey.entityName], localModel.entities[reference.referencedKey.entityName], null, depth + 1);
					
					if (subLabel === "") {
						subLabel = Object.values(model.entities[reference.referencedKey.entityName].keys).filter(key => key.primaryKey)[0].attributes[0].name + ", ";
					}
					
					str += reference.referenceAttributeName + "{ " 
							+ subLabel
							+ "}, ";
				});
		}
		
		return str;
	}
	
	getLabel(item, entityModel, entityLocalModel) {
		if (item == null) {
			return null;
		}
		
		const model = this.props.context.model;

		let labels = Object.values(entityModel.attributes)
			.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].label && (entityLocalModel.attributes[attribute.name].labelLanguage == null || entityLocalModel.attributes[attribute.name].labelLanguage == i18n.language))
			.map(attribute => {
				return {
					order: entityLocalModel.attributes[attribute.name].order,
					label: (item[attribute.name] == null ? "" : (attribute.type == "CUSTOM_TYPE" && entityLocalModel.attributes[attribute.name].type == "DOCUMENT" && item[attribute.name] != null ? <img style={{verticalAlign: "middle", maxHeight: "32px", maxWidth: "64px", paddingRight: 10}} src={(item[attribute.name] == null ? "" : item[attribute.name].thumbnail)}></img> : <span style={{paddingRight: 10}}>{(entityLocalModel.attributes[attribute.name].prefix == null ? "" : entityLocalModel.attributes[attribute.name].prefix) + (attribute.type === 'INTEGER' || attribute.type == 'SERIAL' || attribute.type == 'SMALLINT' || attribute.type == 'BIGINT' || attribute.type == 'SMALLSERIAL' || attribute.type == 'BIGSERIAL' || attribute.type == 'DECIMAL' || attribute.type == 'MONEY' || attribute.type == 'DOUBLE_PRECISION' || attribute.type == 'REAL' ? (entityLocalModel.attributes[attribute.name].step == null ? new Number(item[attribute.name]).toLocaleString(navigator.language.replace("es", "de"), { useGrouping: !entityLocalModel.attributes[attribute.name].disableThousandsSeparator }) : new Number(item[attribute.name]).toLocaleString(navigator.language.replace("es", "de"), { useGrouping: !entityLocalModel.attributes[attribute.name].disableThousandsSeparator, minimumFractionDigits: (Math.floor(entityLocalModel.attributes[attribute.name].step) == entityLocalModel.attributes[attribute.name].step ? 0 : entityLocalModel.attributes[attribute.name].step.toString().split(".")[1].length), maximumFractionDigits: (Math.floor(entityLocalModel.attributes[attribute.name].step) == entityLocalModel.attributes[attribute.name].step ? 0 : entityLocalModel.attributes[attribute.name].step.toString().split(".")[1].length)})) : item[attribute.name]) + (entityLocalModel.attributes[attribute.name].suffix == null ? "" : entityLocalModel.attributes[attribute.name].suffix)}</span>)),
				}
			});
		
		Object.values(entityModel.references)
			.filter(reference => reference.referenceAttributeName != null && entityLocalModel.attributes[reference.referenceAttributeName] != null && entityLocalModel.attributes[reference.referenceAttributeName].label && (entityLocalModel.attributes[reference.referenceAttributeName].labelLanguage == null || entityLocalModel.attributes[reference.referenceAttributeName].labelLanguage == i18n.language))
			.forEach(reference => {
				labels.push({
					order: entityLocalModel.attributes[reference.referenceAttributeName].order,
					label: this.getLabel(item[reference.referenceAttributeName], model.entities[reference.referencedKey.entityName], localModel.entities[reference.referencedKey.entityName])
				});
			});
		
		return <>{labels.sort((a, b) => (a.order == null ? Infinity : a.order) - (b.order == null ? Infinity : b.order)).filter(label => label != null && label.label != null).map(label => label.label)}</>;
	}
	
	getLanguage(lang) {
		let language = "ENGLISH";
		switch (lang) {
			case 'da':
				return 'DANISH';
			case 'nl': 
				return 'DUTCH';
			case 'en_GB': 
				return 'ENGLISH';
			case 'en_US': 
				return 'ENGLISH';
			case 'fi': 
				return 'FINNISH';
			case 'fr_CA': 
				return 'FRENCH';
			case 'fr_FR': 
				return 'FRENCH';
			case 'de': 
				return 'GERMAN';
			case 'it': 
				return 'ITALIAN';
			case 'no': 
				return 'NORWEGIAN';
			case 'pt_BR': 
				return 'PORTUGUESE';
			case 'pt_PT': 
				return 'PORTUGUESE';
			case 'ro': 
				return 'ROMANIAN';
			case 'ru': 
				return 'RUSSIAN';
			case 'es_419': 
				return 'SPANISH';
			case 'es_ES': 
				return 'SPANISH';
			case 'sv': 
				return 'SWEDISH';
			case 'tr': 
				return 'TURKISH';
			default: 
				return 'ENGLISH';
		}
	}
	
	refreshData(inputValue, resolve) {
		const model = this.props.context.model;
		
		const entityModel = model.entities[this.props.entityName];
		const entityLocalModel = localModel.entities[this.props.entityName];
		
		let searchAttributeNames = [];
		let searchCriteria = inputValue;
		
		searchCriteria = (searchCriteria == null || searchCriteria.replace(/[^Á-ÚA-Z0-9ñÑ\-\_\s]/gi, '').trim() === "" ? null : searchCriteria.replace(/[^Á-ÚA-Z0-9ñÑ\-\_\s]/gi, '').trim().split(/\s+/).join(":* &") + ":*");
		Object.values(entityModel.keys).filter(key => key.textSearch).map(key => Object.values(key.attributes).map(attribute => attribute.name in searchAttributeNames ? null : searchAttributeNames.push(attribute.name)));
		
		let keyAttribute = Object.values(entityModel.keys).filter(key => key.primaryKey)[0].attributes[0];
		
		let where = "";
		if (searchCriteria != null && searchAttributeNames.length > 0 
				|| this.props.additionalFilter != null) {
			where += " where: { ";
			
			if (searchCriteria != null && searchAttributeNames.length > 0) {
				where += " " + searchAttributeNames[0] + ': {SEARCH: {query: "' + searchCriteria + '" config: ' + this.getLanguage(entityLocalModel.language) + '}} ';
			}
			
			if (this.props.additionalFilter != null) {
				where += " " + this.props.additionalFilter + " ";
			}
			
			where += " } ";
		}
		
		const query = 
				'{ ' +
				'	result:' + this.props.entityName.replace(".", "_") + 'List(' +
				'		limit: 1000' +
				( Object.values(entityModel.attributes)
						.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].label && (entityLocalModel.attributes[attribute.name].labelLanguage == null || entityLocalModel.attributes[attribute.name].labelLanguage == i18n.language) && !attribute.array).length > 0 
						? '       orderBy: [' +
							(entityLocalModel.defaultOrderAttribute != null && "{ attribute: " + entityLocalModel.defaultOrderAttribute.name + " direction: " + (entityLocalModel.defaultOrderDesc ? 'DESC' : 'ASC') + " nullsGo: " + (entityLocalModel.defaultOrderNullsLast ? 'LAST' : 'FIRST') + "} "
							|| Object.values(entityModel.attributes)
								.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].type != "DOCUMENT" && entityLocalModel.attributes[attribute.name].label && (entityLocalModel.attributes[attribute.name].labelLanguage == null || entityLocalModel.attributes[attribute.name].labelLanguage == i18n.language) && !attribute.array)
								.slice(0, 1)
								.map(attribute => "{ attribute: " + attribute.name + " nullsGo: LAST } ")) + "]" 
						: '') + 
				where +
					'	) {' + keyAttribute.name + ',' +
					(this.props.additionalAttributes != null ? this.props.additionalAttributes.join(', ') + ', ' : '') +
					'       ' + this.getLabelAttributesQueryString(entityModel, entityLocalModel, keyAttribute) + 
					'	} ' +
				'}';

		const variables = {
    		authorization: this.props.context.accessToken
    	};
		let request = JSON.stringify({query: query, variables: variables});
		fetch(this.props.context.baseUrl + "/graphql", {
			method: "POST",
			body: request
		})
		.then(response => response.json())
		.then(json => {
			if (json != null
					&& json.errors != null
					&& json.errors[0] != null
					&& json.errors[0].message == "SessionTimeout") {
				document.location = '/login';
			}
			
			let result = [];
			if (json.data != null && json.data["result"] != null) {
				json.data["result"].forEach(item => {
					let label = this.getLabel(item, entityModel, entityLocalModel);
					let resultItem = {value: item[keyAttribute.name], label: label};
					if (this.props.additionalAttributes != null) {
						this.props.additionalAttributes.forEach(additionalAttribute => resultItem[additionalAttribute] = item[additionalAttribute]);
					}
					return result.push(resultItem);
				});
			}
			resolve(result);
		});
	}
	
	handleLoadOptions(inputValue) {
		return new Promise(resolve => {
			clearTimeout(this.timeoutId);
			this.timeoutId = setTimeout(() => {
				this.refreshData(inputValue, resolve);
			}, 200);
		});
	}
	
	render() {
		const { classes, theme } = this.props;
		
		const selectStyles = {
			input: base => ({
				...base,
				color: theme.palette.text.primary,
				'& input': {
					font: 'inherit',
				},
			}),
		};
		const { t } = this.props;
		return (
			<div className={classes.root}>
				<AsyncSelect
				 		className={classes.alignSelect}
						isClearable
						defaultOptions
						ref={this.props.asyncRef}
						loadOptions={this.handleLoadOptions}
						classes={classes}
						styles={selectStyles}
						autoFocus={this.props.autoFocus}
						isDisabled={this.props.disabled}
						loadingMessage={() => t('loading')}
						noOptionsMessage={() => t('noOptions')}
						textFieldProps={{
							required: this.props.required,
							disabled: this.props.disabled,
							label: this.props.label,
							InputLabelProps: {
								shrink: true,
							},
						}}
						components={{
							Control,
							Menu,
							MultiValue,
							NoOptionsMessage,
							Option,
							Placeholder,
							SingleValue,
							ValueContainer,
							//IndicatorsContainer,
						}}
						placeholder={t('selectValue')}
						onChange={this.props.onChange}
				>
				</AsyncSelect>
				{
					!this.props.context.model.disableReferenceWidgetButtons &&
					<>
						<Tooltip title={t('open')} className={classes.alignButton} disableFocusListener>
							<IconButton
									disableRipple
									aria-label={t('open')}
									onClick={this.handleOpenClick}>
								<OpenInBrowserIcon/>
							</IconButton>
						</Tooltip>
						<Tooltip title={t('manage')} className={classes.alignButton} disableFocusListener>
							<IconButton
									disableRipple
									aria-label={t('manage')}
									onClick={this.handleManageClick}>
								<SettingsIcon/>
							</IconButton>
						</Tooltip>
					</>
				}
			</div>
		);
	}
}

AutoComplete.propTypes = {
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default withStyles(styles,{withTheme:true})(withContext(withI18n()(AutoComplete)));
